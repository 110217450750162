document.getElementById('moveToExport').addEventListener('click', () => {
    scrollTo()
})

function scrollTo() {
    let target = document.getElementById('worldMapSelector');
    target.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
    })
}
