// Contact form submit
let contactForm = document.getElementById('contactForm')
console.log('testas')

if(contactForm) {
    contactForm.addEventListener('submit', function(e) {
        e.preventDefault()
        ajax('/api/contact-form-send', '#contactForm')
    })
}

// Cv from submit
let cvForm = document.getElementById('cvForm')

if(cvForm) {
    cvForm.addEventListener('submit', function(e) {
        e.preventDefault()
        ajax('/api/cv-form-send', '#cvForm')
    })
}

/**
 * Make ajax call to backend
 * @param url
 * @param formId
 */
function ajax(url, formId) {
    // get form data
    let formData = new FormData( document.querySelector(formId))
    startLoadingAnimation()

    // send ajax request
    axios.post(url, formData)
        .then(res => {
            console.log('Send')
            drawError([])
            stopLoadingAnimation()
        })
        .catch(error => {
            let errors = error.response.data.errors
            drawError(errors)
            stopLoadingAnimation()
        })
}

/**
 * Draw error messages
 * @param errors
 */
function drawError(errors) {
    let errorsEl =  document.getElementsByClassName('form-error') // Get element from DOM

    for(var i = 0; i < errorsEl.length; i++) {
      errorsEl[i].classList.remove('show')
      errorsEl[i].innerText = ''
    }

    setTimeout(() => {
        if (typeof errors['accept_policy'] !== "undefined") {
            document.getElementById('formErrorAcceptPolicy').innerText = errors['accept_policy']
            document.getElementById('formErrorAcceptPolicy').classList.add('show')
        }

        if (typeof errors['email'] !== "undefined") {
            document.getElementById('formErrorEmail').innerText = errors['email']
            document.getElementById('formErrorEmail').classList.add('show')
        }

        if (typeof errors['full_name'] !== "undefined") {
            document.getElementById('formErrorFullName').innerText = errors['full_name']
            document.getElementById('formErrorFullName').classList.add('show')
        }

        if (typeof errors['product'] !== "undefined") {
            document.getElementById('formErrorProduct').innerText = errors['product']
            document.getElementById('formErrorProduct').classList.add('show')
        }

        if (typeof errors['first_name'] !== "undefined") {
            document.getElementById('formErrorFirstName').innerText = errors['first_name']
            document.getElementById('formErrorFirstName').classList.add('show')
        }

        if (typeof errors['last_name'] !== "undefined") {
            document.getElementById('formErrorLastName').innerText = errors['last_name']
            document.getElementById('formErrorLastName').classList.add('show')
        }

        if (typeof errors['job_position'] !== "undefined") {
            document.getElementById('formErrorJobPosition').innerText = errors['job_position']
            document.getElementById('formErrorJobPosition').classList.add('show')
        }
    }, 500)

}

function startLoadingAnimation() {
    let width = document.getElementById('formSubmitButton').offsetWidth;
    document.getElementById('loadingButton').style.width = width

    document.getElementById('formSubmitButton').classList.add('-hide');
    document.getElementById('loadingButton').classList.add('-active');
}

function stopLoadingAnimation() {
    setTimeout(() => {
        document.getElementById('formSubmitButton').classList.remove('-hide');
        document.getElementById('loadingButton').classList.remove('-active');
    }, 500)

}
