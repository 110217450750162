require('./bootstrap');
// Load scripts by page
require('./loadScripts');

document.addEventListener("DOMContentLoaded", function() {
    document.getElementById('menu-burger').addEventListener('click', () => {
        document.getElementById('menu-burger').classList.toggle("active");
        document.getElementById('menuCard').classList.toggle("active");
    })
});

document.getElementById('localeSwitch').addEventListener('click', () => {
    document.getElementById('localeSwitch').classList.add('active')
})

let specifiedElement = document.getElementById('localeSwitch');

document.addEventListener('click', function(event) {
  let isClickInside = specifiedElement.contains(event.target);

  if (!isClickInside) {
    document.getElementById('localeSwitch').classList.remove('active')
  }
})
