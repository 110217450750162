let customFileInput = document.getElementById('customFileInput')
let realInput = customFileInput.getElementsByTagName('input')

if (realInput.length > 0) {
    realInput[0].addEventListener('change', showFileName)
}

// get file name and set to text field
function showFileName() {
    let fileName = realInput[0].files[0].name

    let container = customFileInput.getElementsByClassName('custom-input')
    console.log(container)
    if (container.length <= 0) return

    let customFile = container[0].getElementsByClassName('file-name')

    if(customFile.length <= 0) return

    customFile[0].textContent = fileName.length > 25 ? fileName.slice(0, 24) + '...' : fileName
}
