export function checkIfInView(id) {

    let el = document.querySelector('#' + id)
    let bounding = el.getBoundingClientRect()

    return bounding.top >= -250 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 250;

}
