// acceptCookies

let cookies = document.getElementById('acceptCookies')

cookies.addEventListener('click', () => {
    document.cookie = "accept_cookies=true";

    document.getElementById('cookiePopup').classList.remove('-show')

    setTimeout(() => {
        document.getElementById('cookiePopup').classList.add('-disable')
    }, 400)
})

window.addEventListener('load', showCookie, false )

function showCookie() {
    let acceptCookie = document.cookie.match(/^(.*;)?\s*accept_cookies\s*=\s*[^;]+(.*)?$/)

    if (acceptCookie === null) {
        document.getElementById('cookiePopup').classList.remove('-disable')
        setTimeout(() => {
            document.getElementById('cookiePopup').classList.add('-show')
        }, 100)
    }
}

