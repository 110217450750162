let productScroll = document.querySelectorAll('.scroll-to-product')

productScroll.forEach(product => {
    product.addEventListener('click', function() {
        let product = this.dataset.product

        if(product) {
            document.getElementById(product).scrollIntoView({
                behavior: 'smooth'
            })
        }
    })
})
