let currentSlideIndex = 0
let maxLength = 0
let slideAnimationInterval = 5000
let slideAnimation

window.addEventListener('load', initSlider, false )

function initSlider() {
    maxLength = parseInt(getSlideSize())

    document.querySelectorAll('.product .right-card .pagination .line').forEach(el => {
        el.addEventListener('click', (e) => {
            let index = e.currentTarget.dataset['index']
            showCurrentSlide(index)
        })
    })

    animateSlides()
}

function animateSlides() {
    slideAnimation = setInterval(() => {
        // set second slide index
        getCurrentSlideIndex()
        animateElements(currentSlideIndex)

    }, slideAnimationInterval)
}

function animateElements(index) {
    // change slide title
    switchProductTitle(index)

    // change slide image
    switchProductImage(index)

    // change slide indicator
    changeActiveIndicator(index)
}

function showCurrentSlide(index) {
    clearInterval(slideAnimation)

    currentSlideIndex = index

    animateElements(currentSlideIndex)
    animateSlides()
}

function getCurrentSlideIndex() {
    // if index is last slide index + one set it to 0
    currentSlideIndex >= maxLength ? currentSlideIndex = 0 : currentSlideIndex++

    return currentSlideIndex
}

/**
 * get all slides count from html
 * @returns {string}
 */
function getSlideSize() {
    let prodCont = document.getElementById('productContainer')
    return prodCont.dataset.productCount
}

/**
 * change title by current slide index
 * @param index
 */
function switchProductTitle(index) {
    changeElementStatus('.product-title.active', 'homeProductTitle', index)
}

function switchProductImage(index) {
    changeElementStatus('.bg-img img.active', 'homeProductImg', index)
}

function changeActiveIndicator(index) {
    changeElementStatus('.line.active', 'line', index)
}

function changeElementStatus(selector, id, index) {
    let lastActiveLine = document.querySelectorAll(selector)

    lastActiveLine.forEach(el => {
        el.classList.remove('active')
    })

    let newElId = id + index
    document.getElementById(newElId).classList.add('active')
}

