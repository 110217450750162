import { checkIfInView } from '../helpers/viewPort'

const INTERVAL = 2000
const MAX_COUNTRIES = 3
let animationPlaying = false
let mapAnimation
let currentCountryIndex = 0

const MAP_INDEX = {
    '1': 'usa',
    '2': 'europe',
    '3': 'united-kingdom',
}

window.addEventListener('load', animateWorldMap, false )

document.addEventListener('scroll', () => {
    animateWorldMap()
})

function animateWorldMap() {
    let startMapAnim = checkIfInView('worldMapSelector')

    if(!startMapAnim) {
        stopAnimation()
        return
    }

    startAnimation()
}

function startAnimation() {
    if (animationPlaying) return

    mapAnimation = setInterval(() => {
        let index = getCurrentCountryIndex()
        let id = 'countrySwitch' + index

        removeActiveClass()

        document.getElementById(id).classList.add('active')
        switchMap(index)
    }, INTERVAL)

    animationPlaying = true
}

function stopAnimation() {
    if (!animationPlaying) return

    clearInterval(mapAnimation)
    animationPlaying = false
}

function getCurrentCountryIndex() {
    if(currentCountryIndex === MAX_COUNTRIES) {
        currentCountryIndex = 1
        return currentCountryIndex
    }

    currentCountryIndex++
    return currentCountryIndex
}

function removeActiveClass() {
    let elements = document.getElementById('worldMapSelector').getElementsByClassName('active')
    for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('active')
    }
}

function switchMap(index) {
    let map = document.querySelectorAll('#worldMap .' + MAP_INDEX[index])

    map.forEach(e => {
        e.style.fill = '#D60505'
        e.style.stroke = '#FFFFFF'
    })

    let last = parseInt(index) - 1

    if(last <= 0) last = 3

    let lastMap = document.querySelectorAll('#worldMap .' + MAP_INDEX[last])

    lastMap.forEach(e => {
        e.style.fill = '#FFFFFF'
        e.style.stroke = '#212121'
    })

}
