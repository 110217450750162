window.addEventListener('load', initAnimations, false )

window.addEventListener('scroll', initAnimations)

function initAnimations() {
    let elements = document.querySelectorAll('.animate:not(.-active)')
    let interval = 200
    let element = 0

    elements.forEach(el => {
        let inView = isInViewport(el)
        if(inView) {
            setTimeout(() => {
                el.classList.add('-active')
            }, interval * element)
            element++
        }
    })
}

function isInViewport(element) {
    const height = element.offsetHeight;
    const window_height = window.innerHeight;
    const rect = element.getBoundingClientRect();

    if(window_height <= (height - 320)) {
        return (
            rect.top <= window.scrollY
        )
    } else {
        return (
            rect.top >= (window.scrollY <= 100 ? -60 : 60) &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 250
        )
    }

    // return (
    //     rect.top >= (window.scrollY <= 100 ? -60 : 60) &&
    //     rect.left >= 0 &&
    //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    //     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    // )
}
