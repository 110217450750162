window.addEventListener('load', addTableCollapse, false )

function addTableCollapse() {
    let collapseBtns = document.querySelectorAll('.has-small-table .collapse-btn-holder span.collapse');

    collapseBtns.forEach((el) => {
        el.addEventListener('click', collapseTable)
    })
}

function collapseTable() {
    let prdctTablesHolder = document.querySelectorAll('.has-small-table');

    prdctTablesHolder[0].classList.contains('display-big-table') ? prdctTablesHolder[0].classList.remove('display-big-table') : prdctTablesHolder[0].classList.add('display-big-table');
}
