// Load custom select script if that field exists
if (document.getElementsByClassName('custom-select').length > 0) {
    require('./scripts/customSelect')
}

// Load custom select script if that field exists
if (document.getElementById('customFileInput')) {
    require('./scripts/customFileInput')
}

// Load scroll to product
if(document.getElementById('productScroll')) {
    require('./scripts/scrollToProduct')
}

if(document.getElementById('worldMapSelector')) {
    require('./scripts/mapAnimation')
}

// Load home page product slider script
if(document.getElementById('homePageProductSlider')) {
    require('./scripts/homePageProductSlider')
}

// Load cookie pop-up script
if(document.getElementById('cookiePopup')) {
    require('./scripts/cookiePopup')
}

// Load cookie animations script
if(document.getElementsByClassName('animate').length > 0) {
    require('./scripts/animation')
}

// Load cookie gallery script
if(document.getElementsByClassName('gallery-view').length > 0) {
    require('./scripts/gallery')
}

// load ajax script
if(document.getElementById('contactForm') || document.getElementById('cvForm')) {
    require('./scripts/ajax')
}

// load smooth scroll script
if(document.getElementById('worldMapSelector')) {
    require('./scripts/smoothScroll')
}

// loads table collapse
if(document.getElementsByClassName('has-small-table').length > 0) {
    require('./scripts/smallBigTableDisplay')
}
