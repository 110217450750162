let images = document.querySelectorAll('.product-images img')
let imgContainer = document.getElementById('galleryImage')
let gallery = JSON.parse(document.getElementById('images_gal').dataset.images)
let index = 0

images.forEach(img => {
    gallery.push(img.getAttribute('src'))

    img.addEventListener('click', (e) => {
        setGalleryIndex(e.currentTarget.getAttribute('src'))
        firstImageSet()

        let gallery_holder = document.getElementById('gallery')

        gallery_holder.classList.remove('-display-none')
        setTimeout(() => {
            gallery_holder.classList.add('-active')
        }, 100)
    })
})

document.getElementById('closeGallery').addEventListener('click', () => {
    let gallery_holder = document.getElementById('gallery')

    gallery_holder.classList.remove('-active')
    setTimeout(() => {
        gallery_holder.classList.add('-display-none')
    }, 300)
})

document.getElementById('nextImage').addEventListener('click', nextImage)
document.getElementById('prevImage').addEventListener('click', prevImage)

function setGalleryIndex(img) {
    index = gallery.findIndex(el => el === img)
}

function firstImageSet() {
    changeImgSrc()
}

function changeImgSrc(src) {
    imgContainer.src = gallery[index]
}

function nextImage() {
    index++

    if (index === gallery.length) index = 0

    changeImgSrc()
}

function prevImage() {
    index--

    if (index === -1) index = gallery.length - 1

    changeImgSrc()
}
